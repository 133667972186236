<script>
import CustomerSelector from '@/components/customerSelector';
import mixin from './mixin';
export default {
  // 客户档案 系统组件
  name: 'CCustomerSelector',
  inject: {
    customerVal: {
      default: '',
    },
  },
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: ()=>'请选择客户',
    },
  },
  components: {
    CustomerSelector,
  },
  data () {
    return {
      renderKey: this.customerVal?.renderKey || '',
      customerId: this.value?.id || this.customerVal?.customerId,
      customerName: this.value?.name,
    };
  },
  watch: {
    value () {
      this.customerId = this.value?.id;
      this.customerName = this.value?.name;
    },
  },
  methods: {
    handleCustomerChanged (data) {
      this.customerId = data?.id;
      this.customerName = data?.name;
      this.$emit('input', data);
    },
  },
  render () {
    return (
      <customer-selector
        renderKey={this.renderKey}
        size={this.inputSize}
        placeholder={this.placeholder}
        value={this.customerId}
        name={this.customerName}
        readonly={this.inputDisabled}
        onChange={this.handleCustomerChanged}></customer-selector>
    );
  },
};
</script>
