<template>
  <virtual-selector
    :isLoading="loading"
    :placeholder="placeholder"
    v-model="defaultValue"
    :name.sync="defaultName"
    :optionList="optionList"
    :readonly="readonly"
    filterable
    remote
    :option="{
      label: 'name',
      key: 'id',
    }"
    :size="size"
    :remoteMethod="handleSearch"
    :load-more="loadMore"
    @change="handleChange">
  </virtual-selector>
</template>
<script>
import ContactApi from '@/api/contact';
import VirtualSelector from '@/components/common/virtualSelector';
import axios from 'axios';

export default {
  name: 'CONTACT_SELECTOR',
  components: {
    VirtualSelector,
  },
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
    },
    isPermissions: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default () {
        return '请选择客户联系人';
      },
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultValue: this.value,
      defaultName: this.name,
      pageIndex: 1,
      isReachLast: false,
    };
  },
  watch: {
    value () {
      this.defaultValue = this.value;
    },
    name () {
      this.defaultName = this.name;
    },
  },
  methods: {
    getList (keyword) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.loading = true;
      ContactApi.getContactList({
        name: keyword,
        customerStatus: 'EFFECTIVE',
        customerId: this.customerId,
        pageNum: this.pageIndex,
        pageSize: 10,
        isPermissions: this.isPermissions,
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      }).then(res => {
        this.loading = false;
        this.isReachLast = (res.body?.records?.length || 0) < 10;
        this.optionList = this.optionList.concat(res.body.records || []);
      }).catch((res) => {
        if (res?.heads?.code !== -100) {
          this.loading = false;
        }
      });
    },
    handleChange (item) {
      this.$emit('input', item?.id);
      this.$emit('update:name', item?.name);
      this.$emit('change', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.optionList.splice(0, this.optionList.length);
      this.getList(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getList(keyword);
      }
    },
  },
};

</script>
