<script>
import _ from 'lodash';
import ContactApi from '@/api/contact';
import mixin from './mixin';
export default {
  // 客户联系地址选择 系统组件
  name: 'CContactAddressSelector',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    contactPersonId: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '请选择联系地址',
    },
  },
  data () {
    return {
      selected: _.cloneDeep(this.value),
      contactAddressList: [],
    };
  },
  watch: {
    value () {
      this.selected = _.cloneDeep(this.value);
    },
    contactPersonId () {
      this.contactPersonId && this.getContactAddressList();
      if (!this.contactPersonId) this.contactAddressList = [this.value];
    },
  },
  methods: {
    handleAddressChanged () {
      this.$emit('input', this.selected);
    },
    handleClear () {
      this.$emit('input', null);
    },
    getContactAddressList () {
      ContactApi.getDetail(this.contactPersonId).then(t=>{
        this.contactAddressList = t.body.contactAddress || [];
      });
    },
  },
  render () {
    if (!this.contactPersonId) {
      return (
        <el-select size={this.inputSize}
          disabled={this.inputDisabled}
          v-model={this.selected}
          placeholder={this.placeholder}
          clearable
          onClear={this.handleClear}
          onChange={this.handleAddressChanged}
          value-key="id">
          {
            this.contactAddressList.map(i=>(
              <el-option label={i.provinceName + i.cityName + i.areaName + i.address} value={i} key={i.id}></el-option>
            ))
          }
        </el-select>
      );
    }
    return (
      <el-select size={this.inputSize}
        disabled={this.inputDisabled}
        v-model={this.selected}
        placeholder={this.placeholder}
        clearable
        onClear={this.handleClear}
        onChange={this.handleAddressChanged}
        value-key="id">
        {
          this.contactAddressList.map(i=>(
            <el-option label={i.address} value={i} key={i.id}></el-option>
          ))
        }
      </el-select>
    );
  },
  created () {
    if (!this.readonly && this.contactPersonId) {
      this.getContactAddressList();
    }
    if (!this.contactPersonId) {
      this.contactAddressList = [this.value];
    }
  },
};
</script>
