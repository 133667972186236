<script>
import ContactSelector from '@/components/contactSelector';
import mixin from './mixin';
export default {
  // 客户联系人 系统组件
  name: 'CContactSelector',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    customerId: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请选择联系人',
    },
  },
  components: {
    ContactSelector,
  },
  data () {
    return {
      contactUserId: this.value?.id,
      contactUserName: this.value?.name,
    };
  },
  watch: {
    value () {
      this.contactUserId = this.value?.id;
      this.contactUserName = this.value?.name;
    },
  },
  methods: {
    handleUserChanged (data) {
      this.contactUserId = data?.id;
      this.contactUserName = data?.name;
      this.$emit('input', data);
    },
  },
  render () {
    return (
      <contact-selector
        size={this.inputSize}
        placeholder={this.placeholder}
        value={this.contactUserId}
        name={this.contactUserName}
        readonly={this.inputDisabled}
        customerId={this.customerId}
        onChange={this.handleUserChanged}></contact-selector>
    );
  },
};
</script>
